//import Api from "../../test/Api_json_serve";
import Api from "../../Api";

const END_POINT_ALLCUSTOMERS = 'user/clients/all';
const END_POINT_CREATECUSTOMERS = 'user/clients/create';
const END_POINT_UPDATECUSTOMERS = 'user/clients/update';
const END_POINT_DELETECUSTOMERS = 'user/clients/delete';



export default {
    GetAllCustomers(data) {
        return Api.get(END_POINT_ALLCUSTOMERS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },
    PostCreateCustomer(data, auth) {
        if (data.contacto || data.domicilio) {
            return Api.post(END_POINT_CREATECUSTOMERS, {
                "client_type_id": data.tipo_cliente,
                "nombre_comercial": data.nombre_comercial,
                "razon_social": data.razon_social,
                "contacts": data.contacto,
                "addresses": data.domicilio,
                "comentarios": data.comentarios,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + auth.access_token
                }
            });
        }

        return Api.post(END_POINT_CREATECUSTOMERS, {
            "client_type_id": data.tipo_cliente,
            "nombre_comercial": data.nombre_comercial,
            "razon_social": data.razon_social,
            "comentarios": data.comentarios,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });

    },

    UpdateByClientes(data, auth) {
        return Api.patch(END_POINT_UPDATECUSTOMERS, {
            'id': data.id,
            "client_type_id": data.tipo_cliente,
            "nombre_comercial": data.nombre_comercial,
            "razon_social": data.razon_social,
            "contacts": data.contacto,
            "addresses": data.domicilio,
            "comentarios": data.comentarios,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByClientes(id, auth) {
        return Api.delete(END_POINT_DELETECUSTOMERS + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }
}