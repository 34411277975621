import Api from "../../Api";

const END_POINT_ALLALMACEN = 'user/location/warehouse/all';
const END_POINT_CREATEALMACEN = 'user/location/warehouse/create';
const END_POINT_UPDATEALMACEN = 'user/location/warehouse/update';
const END_POINT_DELETEALMACEN = 'user/location/warehouse/delete';

export default {

  GetAllAlmacenes(data){
    return Api.get(END_POINT_ALLALMACEN, {
      headers: {
          'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  PostCreateAlmacen(data, auth) {
    return Api.post(END_POINT_CREATEALMACEN, {
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateAlmacen(data, auth) {
    return Api.patch(END_POINT_UPDATEALMACEN, {
      'id': data.id,
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteAlmacen(id, auth) {
    return Api.delete(END_POINT_DELETEALMACEN + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }

}