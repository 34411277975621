import Api from "../../Api";

const END_POINT_ALLORDENSERVICIO = 'user/order/service-order/all';
const END_POINT_CREATEORDENSERVICIO = 'user/order/service-order/create';
const END_POINT_UPDATEORDENSERVICIO = 'user/order/service-order/update';

export default{
  GetAllOrdenServicio(data){
    return Api.get(END_POINT_ALLORDENSERVICIO, {
      headers: {
        'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  CreateOrdenServicio(data, auth){
    return Api.post(END_POINT_CREATEORDENSERVICIO, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateOrdenServicio(data,auth){
    return Api.patch(END_POINT_UPDATEORDENSERVICIO, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  }

}