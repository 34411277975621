import Api from "../../Api";

const END_POINT_ALLORDENPRODUCCION = 'user/order/production-order/all';
const END_POINT_VIEWORDENPRODUCCION = 'user/order/production-order/view';
const END_POINT_CREATEORDENPRODUCCION = 'user/order/production-order/create';
const END_POINT_UPDATEORDENPRODUCCION = 'user/order/production-order/update';

export default{
  GetAllOrdenProduccion(data){
    return Api.get(END_POINT_ALLORDENPRODUCCION, {
      headers: {
        'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  GetProductionOrder(id, auth) {
    const options = {
        headers: {'Authorization': 'Bearer ' + auth.access_token},
        params: { id: id }
    }
    return Api.get(END_POINT_VIEWORDENPRODUCCION,options);
  },

  CreateProductionOrder(data,auth){
    return Api.post(END_POINT_CREATEORDENPRODUCCION, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateOrdenServicio(data,auth){
    return Api.patch(END_POINT_UPDATEORDENPRODUCCION, data, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  }

}