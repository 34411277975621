import Api from "../../Api";

const END_POINT_ALLSUCURSAL = 'user/location/branch/all';
const END_POINT_CREATESUCURSAL = 'user/location/branch/create';
const END_POINT_UPDATESUCURSAL = 'user/location/branch/update';
const END_POINT_DELETESUCURSAL = 'user/location/branch/delete';

export default {

  GetAllSucursales(data){
    return Api.get(END_POINT_ALLSUCURSAL, {
      headers: {
          'Authorization': 'Bearer ' + data.access_token
      }
    });
  },

  PostCreateSucursal(data, auth) {
    return Api.post(END_POINT_CREATESUCURSAL, {
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  UpdateSucursal(data, auth) {
    return Api.patch(END_POINT_UPDATESUCURSAL, {
      'id': data.id,
      'name': data.name,
    }, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      }
    });
  },

  DeleteSucursal(id, auth) {
    return Api.delete(END_POINT_DELETESUCURSAL + '/' + id, {
      headers: {
        'Authorization': 'Bearer ' + auth.access_token
      },
    });
  }

}