<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Orden de servicio"
      subtitle="| Editar orden de servicio"
      class="heading-block"
    />    
    

    <div>
      <base-block rounded title="">
        <div class="block-content font-size-sm">
          <b-row>
            <b-col sm="8">
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                          Cotización : <span class="text-danger">*</span>
                      </template>
                        <b-form-input
                          v-model="quoteInfo.id"
                          disabled
                          size="sm"
                        ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Cliente : <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-model="quoteInfo.client.nombre_comercial"
                        disabled
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Fecha de recepción: <span class="text-danger">*</span>
                      </template>
                      <b-form-datepicker
                        id="datepicker"
                        v-model="quoteInfo.service_order.fecha_recepcion"
                        placeholder="Fecha de recepción" 
                        class="mb-2"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        disabled
                        ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Hora de recepción: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Seleccione la hora de recepción"
                        v-model="quoteInfo.service_order.hora_recepcion"
                        size="sm"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Orden de compra: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba la orden de compra"
                        v-model="quoteInfo.service_order.orden_de_compra"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Orden de producción: <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-b-tooltip.hover title="Escriba la orden de producción"
                        v-model="quoteInfo.service_order.orden_de_produccion"
                        disabled
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Fecha de entrega: <span class="text-danger">*</span>
                      </template>
                      <b-form-datepicker
                        id="datepicker"
                        v-model="quoteInfo.service_order.fecha_entrega"
                        placeholder="Fecha de entrega" 
                        class="mb-2"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        >
                      </b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                  </b-col>
                  <b-col sm="4" class="mt-2" v-if="false">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Almacén: <span class="text-danger">*</span>
                      </template>
                      <select class="form-control" v-model="quoteInfo.production_order.warehouse_id" disabled>
                        <option :value="data.id" v-for="(data) in almacenesList" :key="data.id">
                          {{ data.name }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" class="mt-2">
                    <b-form-group label-for="no_cliente">
                      <template #label>
                        Almacén: <span class="text-danger">*</span>
                      </template>
                      <select class="form-control" v-model="quoteInfo.service_order.branch_id">
                        <option :value="data.id" v-for="(data) in sucursalesList" :key="data.id">
                          {{ data.name }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </form>
            </b-col>
            <b-col sm="4" class="rounded border p-4 pt-0">
              <FileSystem v-if="quote_id != ''" view="cotizacion" :idItemView="quote_id" :files="files"></FileSystem>
            </b-col>
          </b-row>

          <br />
          <b-row>
            <b-col sm="12" class="mt-4">
              <!-- === PRODUCTO TERMINADO === -->
              <b-row class="ml-4 mt-12">
                <div class="container">
                  <div class="row">
                    <div class="col-5" id="linea">
                      <hr>
                    </div>
                    <div class="col-2" id="titulo">
                      <p>Producto terminado</p>
                    </div>
                    <div class="col-5" id="linea">
                      <hr>
                    </div>
                  </div>
                </div>
              </b-row>

              <b-row class="ml-4 mt-12">
                <b-col sm="12">
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1" v-for="(item_producto_terminado, index_Q_P_T) in selected_list_items_producto_terminado" :key="item_producto_terminado.q_f_p_id">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button id="button_accordion" block v-b-toggle="'accordion-producto-terminado'+index_Q_P_T" variant="info">Producto Terminado: {{item_producto_terminado.no_parte}}</b-button>
                      </b-card-header>
                      <b-collapse :id="'accordion-producto-terminado'+index_Q_P_T" accordion="my-accordion-producto-terminado" role="tabpanel">
                        <b-card-body>
                          <br><br>
                          <b-row class="ml-4 mt-12">
                            <b-col sm="12">
                              <b-row style="padding: 1%;width: 99%;border-width: 1px;border-style: solid;border-color: #d0d0d0;border-radius: 5px;"> 
                                <b-col sm="12">
                                  <b-form-group>
                                    <template #label>
                                      ESPECIFICACIONES
                                    </template>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Código
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.codigo"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col> 
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Descripción
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.descripcion"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Comentarios
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.comentarios"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group>
                                    <template #label>
                                      Cantidad
                                    </template>
                                    <b-form-input
                                      disabled
                                      v-model="item_producto_terminado.no_piezas"
                                      size="sm"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group label-for="largo_solicitado">
                                    <template #label>
                                      Costo unitario
                                    </template>
                                    <b-input-group size="sm" prepend="$">
                                      <b-form-input
                                        disabled
                                        v-model="item_producto_terminado.costo_unitario"
                                        size="sm"
                                      ></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="2">
                                  <b-form-group label-for="largo_solicitado" prepend="$">
                                    <template #label>
                                      Importe
                                    </template>
                                    <b-input-group size="sm" prepend="">
                                      <b-form-input
                                        disabled
                                        input-group-text="$"
                                        v-model="item_producto_terminado.importe"
                                        size="sm"
                                      ></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <br><br>
                              </b-row>
                              <br><br>
                              <br><br>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>

              <!-- === FIN DE PRODUCTO TERMINADO === -->

            </b-col>
          </b-row>
          <br />
        </div>
        <div  class="block-content block-content-full text-right border-top">
          <b-button variant="alt-primary"  class="mr-1"  @click="Cancelar()">Cancelar</b-button>
          <b-button variant="primary" class="mr-1" @click="UpdateOrdenServicio()">Guardar</b-button>
          <b-button variant="primary" class="mr-1" @click="quoteInfo.production_order.id ? ViewOrdenProduccion() : CreateOrdenProduccion()">Guardar y {{ quoteInfo.production_order.id ? 'ver' : 'generar' }} orden de producción</b-button>
        </div>
        </base-block>
    </div>
  </div>
</template>

<script>

  import Clientes from "../../../api/admin/clientes/Clientes"
  import Sucursal from "../../../api/admin/ubicacion/Sucursales"
  import Almacen from "../../../api/admin/ubicacion/Almacenes"
  import PreCotizacion from "../../../api/admin/cotizacion/Pre_cotizacion"
  import OrdenServicio from "../../../api/admin/orden_servicio/Orden_Servicio"
  import OrdenProduccion from "../../../api/admin/orden_produccion/Orden_Produccion"
  import FileSystem from "../../../components/FileSystem.vue"
  import Swal from "sweetalert2"


  export default {

    components: {
      FileSystem
    },

    data() {
      return {
        quote_id:false,
        quoteInfo:{client:[],service_order:[],production_order:[]},
        productoTerminadoList:[],
        currentQuote:0,
        sucursalesList:[],
        almacenesList:[],
        files:[],
        form: {
            cotizacion_id:'',
            client_id:'',
            orden_compra: '5DF8ER2',
            fecha_recepcion: '26-04-2022',
            hora_recepcion:'12:00',
            sucursal:'Sucursal 1',
            almacen:'Almacen 1',
            id_sucursal:null,
            id_almacen:null
        },
        selected_list_items_producto_terminado:[],
      }
    },

    methods: {

      Cancelar(){

      },

      GetQuoteInfo(id){
        const context = this;
        let auth = JSON.parse(localStorage.autentication);
        PreCotizacion.GetQuot(id,auth)
        .then((response) => {
          const responseQuoteInfo = response.data.data
          context.quoteInfo = responseQuoteInfo
          if(responseQuoteInfo.production_order == null){
            context.quoteInfo.production_order = [{id:0,warehouse_id:0}]
            
          }
          context.selected_list_items_producto_terminado = responseQuoteInfo.finished_products
          context.files = responseQuoteInfo.files
          console.log(context.quoteInfo)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      AllClientes() {
        let auth = JSON.parse(localStorage.autentication);
        Clientes.GetAllCustomers(auth)
        .then((response) => {
          this.clientes = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllSucursales(){
        let auth = JSON.parse(localStorage.autentication);
        Sucursal.GetAllSucursales(auth)
        .then((response) => {
          this.sucursalesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      AllAlmacenes(){
        let auth = JSON.parse(localStorage.autentication);
        Almacen.GetAllAlmacenes(auth)
        .then((response) => {
          this.almacenesList = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
      },

      async UpdateOrdenServicio(){
        let auth = JSON.parse(localStorage.autentication)
        const context = this
        OrdenServicio.UpdateOrdenServicio(this.quoteInfo.service_order,auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      },

      ViewOrdenProduccion(){
        const context = this
        context.UpdateOrdenServicio().
        then(()  => {
          context.$router.push({ path: '/ordenes-de-produccion/edit/'+context.quote_id})
        })
      },

      CreateOrdenProduccion(){
        const context = this
        let auth = JSON.parse(localStorage.autentication)

        // los datos que necesito para generar una nueva orden de producción básica son:
        // fecha_recepcion
        // hora_recepcion
        // quote_id

        const data = {
          quote_id : context.quote_id
        }

        OrdenProduccion.CreateProductionOrder(data, auth)
        .then((response) => {
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.GetQuoteInfo(context.quote_id)
        })
        .catch((error) => {
          const obj = error.response.data.errors;
          Object.entries(obj).forEach( elements => 
            Swal.fire({
              title: "Que mal!",
              text: elements[1][0],
              icon: "error",
            })
          );
        });
      }
      
    },    

    mounted() {
      let me = this
      me.quote_id = me.$route.params.id
      me.AllClientes()
      me.AllSucursales()
      me.AllAlmacenes()
      me.GetQuoteInfo(me.quote_id);
    }
  };



</script>


<style scoped>
    #button_accordion{
      background: #272e38;
      border-color: #272e38;
    }

    #titulo {
      text-align: center;
    }

    hr#linea {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #030303;
    }

    hr{
      border-top: 1px solid #000000 !important;
    }

    .v-line{
      border-left: solid #e8dbdb;
      height: 57%;
      left: 63%;
      position: absolute;
    }

    .form-group{
      margin-bottom: 5px!important;
    }

</style>